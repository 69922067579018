<template>
    <div class="image-cropper-modal">
      <el-dialog
          :visible="visible"
          :append-to-body="true"
          :close-on-click-modal="false"
          title="裁剪图片"
          width="80%"
          class="image-cropper-dialog"
          @close="visible = false"
      >
        <vue-cropper
            ref="imageCropper"
            :img="url"
            :auto-crop-width="autoCropWidth"
            :auto-crop-height="autoCropHeight"
            :auto-crop="true"
            :fixed="true"
            :fixed-number="[1, 1]"
            :fixed-box="true"
            :output-size="1"
            output-type="png"
        >
        </vue-cropper>
        <template #footer>
        <span class="dialog-footer">
          <el-button class="common-btn cancel" @click="onCancel">取 消</el-button>
          <el-button class="common-btn confirm" type="primary" @click="onConfirm">确 定</el-button>
        </span>
        </template>
      </el-dialog>
    </div>
</template>
<script>
import {VueCropper} from 'vue-cropper'

export default {
  name: 'ImageCropperModal',
  components: {
    VueCropper
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    },
    autoCropWidth: {
      type: String,
      default: `${104 * 4}`,
    },
    autoCropHeight: {
      type: String,
      default: `${104 * 4}`,
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$refs.imageCropper.getCropBlob((blob) => {
        this.$emit('confirm', blob)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-cropper-dialog {
  .vue-cropper {
    height: 60vh;
  }
}
</style>
