import request from '@/utils/request'

// 用户主页
export function getInfoByName(username, page, size) {
    return request({
        url: '/ums/user/' + username,
        method: 'get',
        params: {
            pageNo: page,
            size: size
        }
    })
}

// 用户主页
export function getInfo() {
    return request({
        url: '/ums/user/info',
        method: 'get'
    })
}

// 更新
export function update(user) {
    return request({
        url: '/ums/user/update',
        method: 'post',
        data: user
    })
}

// 重置密码
export function updatePassWord(user) {
    return request({
        url: '/ums/user/updatePassWord',
        method: 'post',
        data: user
    })
}

//图片上传
export function uploadUserImg(file) {
    return request({
        url: '/post/uploadMdPic',
        method: 'post',
        data: file
    })
}


