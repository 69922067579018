<template>
  <section>
    <el-card shadow="never">
      <div slot="header">
        个人设置
      </div>
      <div class="columns">
        <div class="column is-full">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基础信息" name="first">
              <el-form :label-position="labelPosition" label-width="100px" :model="user" :rules="rules"
                       ref="updateUser">
                <el-form-item label="头像" prop="companyImgUrl">
                  <el-upload
                      v-model="queryParams.companyImgUrl"
                      class="avatar-uploader"
                      action
                      :auto-upload="false"
                      :show-file-list="false"
                      :on-change="changeUpload"
                  >
                    <img v-if="user.avatar" :src="user.avatar" class="avatarUrl">
                    <i v-else class="el-icon-plus avatar-uploader-icon"/>
                    <p>点击头像上传新图片</p>
                  </el-upload>
                </el-form-item>
                <ImageCropperModal
                    :visible="cropperVisible"
                    :url="file"
                    :auto-crop-width="autoCropWidth"
                    :auto-crop-height="autoCropHeight"
                    @cancel="cropperVisible = false"
                    @confirm="onConfirm"
                />
                <el-form-item label="账号">
                  <el-input v-model="user.username" disabled/>
                </el-form-item>
                <el-form-item label="昵称">
                  <el-input v-model="user.alias"/>
                </el-form-item>
                <el-form-item label="性别">
                  <el-select v-model="user.sex" placeholder="请选择您的性别" style="width:100%">
                    <el-option
                        v-for="item in sexOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                    prop="email"
                    label="邮箱"
                    :rules="[
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ]"
                >
                  <el-input v-model="user.email"/>
                </el-form-item>
                <el-form-item label="手机号码" prop="mobile">
                  <el-input v-model="user.mobile" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="简介">
                  <el-input type="textarea" v-model="user.bio" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>

                  <el-button type="primary" icon="el-icon-edit" @click="submitForm('updateUser')">修 改</el-button>
                  <!--                  <el-button type="primary" @click="resetForm('updateUser')">重 置</el-button>-->
                  <el-button type="primary" icon="el-icon-back" @click="return1">返回主页</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="重置密码" name="second">
              <el-form ref="updatePassWord" :model="user" label-width="100px" class="demo-dynamic" :rules="rules">
                <el-form-item label="旧密码" prop="oldPassword">
                  <el-input type="password" v-model="user.oldPassword" autocomplete="off" :show-password="true"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                  <el-input type="password" v-model="user.newPassword" autocomplete="off" :show-password="true"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="confirmPassword">
                  <el-input type="password" v-model="user.confirmPassword" autocomplete="off" :show-password="true"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" icon="el-icon-edit" @click="submitForm('updatePassWord')">修 改</el-button>
                  <el-button type="primary" icon="el-icon-back" @click="return1">返回主页</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-card>
  </section>
</template>

<script>
import {getInfo, update, updatePassWord, uploadUserImg} from '@/api/user'
import ImageCropperModal from '@/views/user/ImageCropperModal.vue'
import md5 from 'md5'

export default {
  name: 'Setting',
  components: {
    ImageCropperModal
  },
  data() {
    //电话号码校验
    const checkPhone = (rule, value, callback) => {
      if (!this.validatePhone.test(value)) {
        return callback(new Error('手机号格式不合法'));
      }
      callback()
    }
    return {
      activeName: 'first',
      labelPosition: 'right',
      sexOptions: [{
        value: 0,
        label: '男'
      }, {
        value: 1,
        label: '女'
      }],
      interval: null,
      validatePhone: /^1[3,4,5,6,7,8,9][0-9]{9}$/,
      user: {
        id: '',
        username: '',
        alias: '',
        sex: '',
        bio: '',
        email: '',
        mobile: '',
        avatar: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {validator: checkPhone, trigger: 'blur'}
        ],
        oldPassword: [
          {required: true, min: 6, max: 20, message: '请输入旧密码，长度在6-20个字符', trigger: 'blur'}
        ],
        newPassword: [
          {required: true, min: 6, max: 20, message: '请输入新密码，长度在6-20个字符', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (value === this.user.oldPassword) {
                callback(new Error('新旧密码不能相同'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        confirmPassword: [
          {required: true, message: '请再次输入新密码', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (value !== this.user.newPassword) {
                callback(new Error('两次输入的密码不一致'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ]
      },
      queryParams: {
        companyImgUrl: ''
      },
      autoCropWidth: '300', // 要裁剪的宽
      autoCropHeight: '300', // 要裁剪的高
      file: '',
      fileName: '', // 存放文件名
      cropperVisible: false // 控制弹窗打开关闭
    }
  },
  created() {
    this.fetchInfo()
  },
  methods: {
    fetchInfo() {
      getInfo(this.$route.params.username).then(res => {
        console.log(res)
        const {data} = res
        this.user = data
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          // console.log(this.user)
          if (formName === 'updateUser') {//修改基础信息
            const data = {
              id: this.user.id,
              username: this.user.username,
              alias: this.user.alias,
              sex: this.user.sex,
              avatar: this.user.avatar,
              email: this.user.email,
              mobile: this.user.mobile,
              bio: this.user.bio
            }
            update(data).then(res => {
              this.$message.success('信息修改成功')
              this.fetchInfo()
            })
          } else {//重置密码
            const data = {
              id: this.user.id,
              oldPassword: md5(this.user.oldPassword),
              newPassword: md5(this.user.newPassword),
              // confirmPassword: md5(this.user.confirmPassword)
            }
            updatePassWord(data).then(res => {
              this.$message.success('密码重置成功，即将退出登录')
              setTimeout(() => {
                this.$router.push({path: this.redirect || '/'})
              }, 1500)
              this.$store.dispatch('user/logout').then(() => {
                /*this.$message.info('退出登录成功')
                setTimeout(() => {
                  this.$router.push({path: this.redirect || '/'})
                }, 500)*/
              })
              // this.fetchInfo()
            })
          }
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 头像上传
    handleAvatarSuccess(res) {
      // res就是头像文件路径
      this.user.avatar = res.url
    },
    return1() {
      this.$router.push("/")
    },
    // 文件状态改变时
    changeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg' || img === 'png' || img === 'jpeg'
      if (!suffix) {
        this.$message.error('只能上传图片！')
        return false
      }
      // URL.createObjectURL的参数只能是blob或者file类型
      // 第一种方法用FileReader，URL.createObjectURL接收blob类型
      const reader = new FileReader()
      reader.onload = () => {
        // 把Array Buffer转化为blob 如果是base64不需要
        this.file = typeof reader.result === 'object' ? window.URL.createObjectURL(new Blob([reader.result]))
            : reader.result
      }
      // 转化为base64
      this.cropperVisible = true
      reader.readAsArrayBuffer(file.raw)

      // 第二种方法，URL.createObjectURL接收file类型
      // this.$nextTick(() => {
      //   this.file = URL.createObjectURL(file.raw)
      //   this.cropperVisible = true
      // })

      this.fileName = file.name
    },
    // 点击剪裁弹框的确定按钮
    async onConfirm(blob) {
      // 这里的new FormData（）指，以文件的方式传给后端（FormData的数据）
      const form = new FormData()
      // new File()的第一个参数是一个字符串数组，数组中的每一个元素对应着文件中一行的内容
      // 第二个参数就是文件名字符串
      // 第三个参数可以设定一些文件的属性，比如文件的MIME，最后更新时间等
      const file = new File([blob], this.fileName, {type: blob.type, lastModified: Date.now()})
      file.uid = Date.now()
      form.append('file', file)
      // 如果想在这里打印查看form的值，会发现它是空对象
      // 解决办法，需要用form.get('键')的方法获取值
      // console.log(form.get('file'))
      // 这里调用接口，获取后端返给的图片地址
      await uploadUserImg(form).then(response => {
        const {url} = response
        this.user.avatar = url
      })
      this.cropperVisible = false
    }
  }
}
</script>

<style scoped>
.avatar-uploader {
  text-align: left;
  padding-bottom: 5px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  line-height: 138px;
  text-align: center;
}

.avatarUrl {
  width: 160px;
  height: 160px;
  display: block;
}

/*.el-input {
  width: 50%;
}
.el-select {
  width: 50%;
}*/
</style>
